.contact_main{
  background-color: #0076BD;
  padding: 2rem;
  margin-top: 2rem;
  /* border: 2px solid #000000C9;
  box-shadow: 15px 15px 8px 0px #00000040 inset;
  border-radius: 10px; */
}
.contact-icon{
  width: 20px;
}

@media (max-width: 640px){
  .contact_main{
    display: none;
  }
}

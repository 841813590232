.skill{
    margin-left: 8%;
}
.skilll{
    margin-left: 15%;
}
.pedagogi_skills{
    box-shadow: 10px 8px 4px rgba(0, 0, 0, 0.25);
}
@media(max-width: 640px){
    .skill{
        margin-left: 0%;
    }
    .skilll{
        margin-left: 0%;
    }
    .pedagogi_skills{
        box-shadow:none;
    }
}
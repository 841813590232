.sportsinfo{
    display: flex;
    flex-direction: row-reverse;
}
.admissionsimage{
    width: 100%;
  }
.houseandclubs{
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.sports{
    flex-direction: row;
}
.house__clubs{
    margin-top: 12px;
    margin-left: -1rem;
}
.house_img{
    width: 18rem;
    height: 12rem;
}
.houseimage{
    width: 74%;
}
@media(max-width: 640px){
    .house__clubs{
        margin-top: -9px;
        margin-left: 3rem;
    }
    .sports{
        display: flex;
        flex-direction: column-reverse;
    }
    
    .sportsinfo{
        display: flex;
        flex-direction: column-reverse;
    }
    .sportsinfoo{
        display: flex;
        flex-direction: column-reverse;
    }
      .houseandclubs{
        margin-left: 0rem;
        margin-right: 1rem;
        margin-top: 0rem;
        margin-bottom: 1rem;
    }
    .house_img{
        width: 80%;
        height: 12rem;
    }
    .houses_img{
        display: flex;
        justify-content: center;
    }
    .houseimage{
        width: 56px;
        margin-top: -16px;
    }
}
.slider-img-div {
  height: 400px;
  margin-left: 5rem;
}
.slider-img-div > img {
  border-radius: 10px;
  object-fit: cover;
}
.slick-prev {
  left: 10px !important;
  z-index: 2;
}
.slick-next:before,
.slick-prev:before {
  color: #c4c4c4;
  font-size: 3rem;
  opacity: 1;
  transition: 200ms;
}
.slick-next {
  right: 35px !important;
  z-index: 2;
}
.slidervideo{
  height: 100%;
}
.slider-img-div{
  margin-left: 2rem;
}
.photo_heading{
  margin-bottom: 1.5rem;
}
@media (max-width: 640px){
  .slider-img-div{
    margin-left: 0rem;
    height: 167px;
  }
  .slidervideo{
    height: 102%;
  }
  .photo_heading{
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}

.home_main{
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.school_name{
  font-size: 50px;
  font-weight: 700;
}
.landing_video{
  object-fit: contain !important;
  margin-top: 9%;
}
.landing_video> video{
  object-fit: contain;
}
@media(max-width:640px){
  .landing_video{
    height: auto !important;
    margin-top: 4rem;
  }
  .school_name{
    font-size: 25px;
    font-weight: 700;
    margin-left: 5px;
  }
}